
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    
import "../public/assets/styles/globals.css";
import "../public/assets/styles/index.css";
import Loader from "../components/Loader";
import Router from "next/router";
import React, { useState, useEffect } from "react";
import ErrorBoundary from "../components/ErrorBoundary";
import Error from "../components/Error";
import Head from "next/head";
import { StytchProvider } from "@stytch/nextjs";
import { createStytchUIClient } from "@stytch/nextjs/ui";

const stytch = createStytchUIClient(
  process.env.NODE_ENV === "production"
    ? process.env.NEXT_PUBLIC_STYTCH_PUBLIC_TOKEN_LIVE
    : process.env.NEXT_PUBLIC_STYTCH_PUBLIC_TOKEN_TEST
);

function MyApp({ Component, pageProps }) {
  useEffect(() => {
    Router.events.on("routeChangeStart", () => {
      SetLoading(true);
    });

    Router.events.on("routeChangeComplete", () => {
      SetLoading(false);
    });
  }, []);
  const [loading, SetLoading] = useState(false);
  return (
    <>
      <Head>
        <link
          rel="shortcut icon"
          href="/assets/images/favicon.png"
          type="image/png"
        />
      </Head>
      {loading && <Loader />}
      <ErrorBoundary FallbackComponent={Error}>
        <StytchProvider stytch={stytch}>
          <Component {...pageProps} />
        </StytchProvider>
      </ErrorBoundary>
    </>
  );
}

const __Page_Next_Translate__ = MyApp;


    export default __appWithI18n(__Page_Next_Translate__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
    });
  