/* This example requires Tailwind CSS v2.0+ */
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import Link from "next/link";
import { useRouter } from "next/router";
import useTranslation from "next-translate/useTranslation";
import EsLogo from "../public/assets/images/es-logo-small.png";
import Image from "next/image";
import { useStytch, useStytchUser } from "@stytch/nextjs";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const NavBars = () => {
  const router = useRouter();
  let { t } = useTranslation();
  const stytch = useStytch();
  const { user } = useStytchUser();
  const navigation = [
    // {
    //   name: t("main:projects"),
    //   href: "/projects",
    //   current: router.pathname === "/projects",
    // },
    {
      name: t("main:jobs"),
      href: "/experiences",
      current: router.pathname === "/experiences",
    },
    {
      name: t("main:certificates"),
      href: "/certificates",
      current: router.pathname === "/certificates",
    },
    {
      name: t("main:gallery"),
      href: "/gallery",
      current: router.pathname === "/gallery",
    },
    // {
    //   name: t("main:blog"),
    //   href: "/blog",
    //   current: router.pathname === "/blog",
    // },
    {
      name: t("main:about"),
      href: "/about",
      current: router.pathname === "/about",
    },
    {
      name: t("main:contact"),
      href: "/contact",
      current: router.pathname === "/contact",
    },
  ];

  const signOutHandler = async () => {
    await stytch.session.revoke();
    router.push("/auth/login");
  };

  return (
    <Disclosure as="nav" className="">
      {({ open }) => (
        <>
          <div className="mx-auto">
            <div className="relative flex items-center justify-between h-16">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                {/* Mobile menu button*/}
                <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                <div className="flex-shrink-0 flex items-center">
                  <Link href="/">
                    {/* <img
                        className="block lg:hidden h-8 w-auto"
                        src="https://tailwindui.com/img/logos/workflow-mark-indigo-500.svg"
                        alt="Workflow"
                      /> */}
                    <Image
                      className="h-8 w-auto cursor-pointer main-logo"
                      src={EsLogo}
                      width={65}
                      height={37}
                      alt="EhsanSafari"
                    />
                  </Link>
                </div>
                <div className="hidden sm:block sm:ml-6">
                  <div className="flex space-x-4">
                    {navigation.map((item) => (
                      <Link
                        key={item.name}
                        href={item.href}
                        aria-current={item.current ? "page" : undefined}
                      >
                        <span
                          className={classNames(
                            item.current
                              ? "bg-white bg-opacity-20 text-white"
                              : "text-gray-300 hover:bg-white hover:bg-opacity-20 hover:text-gray-700 hover:text-white cursor-pointer",
                            "py-2 sm:px-1 md:px-3 sm:text-sm md:text-md lg:text-lg transition duration-500"
                          )}
                        >
                          {item.name}
                        </span>
                      </Link>
                    ))}
                  </div>
                </div>
              </div>
              <div className="absolute inset-y-0 right-0 flex items-center gap-4 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                <div className="flex items-center justify-center">
                  <div className="w-fit my-5 shadow-sm">
                    {user ? (
                      <button
                        className="btn secondary small"
                        onClick={signOutHandler}
                      >
                        ⛔ Logout
                      </button>
                    ) : (
                      <span className="btn info small">
                        <Link href="/auth/login">👨 Login</Link>
                      </span>
                    )}{" "}
                    <span
                      className={`${
                        router.locale !== "fa"
                          ? "bg-gray-300 text-gray-600 cursor-text pointer-events-none"
                          : "cursor-pointer"
                      }`}
                    >
                      <Link locale="en-US" href={router.asPath}>
                        <span className="nav-link">
                          <i className="nc-icon nc-planet" /> En
                        </span>
                      </Link>
                    </span>
                    <span
                      className={`${
                        router.locale === "fa"
                          ? "bg-gray-300 text-gray-600 cursor-text pointer-events-none"
                          : "cursor-pointer"
                      }`}
                    >
                      <Link locale="fa" href={router.asPath}>
                        <span className="nav-link">
                          <i className="nc-icon nc-planet" /> فا
                        </span>
                      </Link>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1">
              {navigation.map((item) => (
                <Disclosure.Button
                  key={item.name}
                  as="a"
                  href={item.href}
                  className={classNames(
                    item.current
                      ? "bg-gray-900 text-white"
                      : "text-gray-300 hover:bg-gray-700 hover:text-white",
                    "block px-3 py-2 rounded-md text-base font-medium"
                  )}
                  aria-current={item.current ? "page" : undefined}
                >
                  {item.name}
                </Disclosure.Button>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export default NavBars;
