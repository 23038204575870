import React from "react";
import useTranslation from "next-translate/useTranslation";
import { navVariants } from "../utils/motions";
import { motion } from "framer-motion";

const Footer = () => {
  let { t } = useTranslation();
  return (
    // <motion.footer
    //   variants={navVariants}
    //   initial="hidden"
    //   whileInView="show"
    //   viewport={{ once: false, amount: 0.25 }}
    //   className="mt-3"
    // >
    //   <small className="opacity-50 ">{t("main:footer_text")}</small>
    // </motion.footer>
    <footer className="mt-3">
      <small className="opacity-50 ">{t("main:footer_text")}</small>
    </footer>
  );
};

export default Footer;
