import React from "react";
import { useRouter } from "next/router";
import Footer from "./Footer";
import NavBars from "./NavBars";
import { motion } from "framer-motion";
import { navVariants } from "../utils/motions";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Layout = ({ children, className }) => {
  const router = useRouter();
  return (
    <main
      className={`${className} min-h-screen flex flex-col items-center justify-between text-gray-200 ${router.pathname.substring(
        1
      )}`}
    >
      <div className="container text-center ">
        <NavBars />
        <ToastContainer autoClose={2000} />
        <div className="mx-auto mt-3rem">
          <motion.div
            variants={navVariants}
            initial="hidden"
            whileInView="show"
            viewport={{ once: false, amount: 0.25 }}
          >
            {children}
          </motion.div>
        </div>
        <Footer />
      </div>
    </main>
  );
};

export default Layout;
